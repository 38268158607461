<template>
	<div>
		<div class="bgtop">
			<div>
				<img class="bgimage" src="../assets/2.png" alt="" />
			</div>
			
			<div class="bgtext">
			
				<div class="bgtextlief" :class="bgtext == 0? 'bgtextlie': ''" @click="getbgtext" :data-index="0">主页</div>
				<div class="bgtextlief" :class="bgtext == 1? 'bgtextlie': ''" @click="getbgtext" :data-index="1">解决方案</div>
				<a class="bgtextlief" :class="bgtext == 2 ? 'bgtextlie' : ''"  :data-index="2" href="https://dpsj2.xqntech.com/#/" target="_blank">商业地图</a>
				<!-- <div >商业地图</div> -->
				<div class="bgtextlief" :class="bgtext == 3? 'bgtextlie': ''" @click="getbgtext" :data-index="3">掌店学堂</div>
				<div class="bgtextlief" :class="bgtext == 4? 'bgtextlie': ''" @click="getbgtext" :data-index="4">联系我们</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	export default{
		name:'TopComponents',
	data() {
	    return {
	        bgtext: 0
	    }
	},
		mounted() {
			const arr = localStorage.getItem('top')
			this.bgtext = arr? arr :0
		},
		methods:{
			getbgtext(e) {
				
			  
			  this.bgtext = e.target.dataset.index
			  localStorage.setItem('top', this.bgtext)
			  const data = this.bgtext
			  if(data == 0){
				  this.$router.push('/About')
			  }else if(data == 1){
				  this.$router.push('/Solut')
			  }else if(data == 3){
				  this.$router.push('/Syhi')
			  }else if(data == 4){
				  this.$router.push('/My')
			  }
			},
			
		}
			
	}
</script>

<style scoped>
	a {
	  text-decoration: none;
	}
	.bgtop{
		/* position: fixed;
		margin-top: 7px; */
		position: absolute;
		z-index: 10; /* 确保内容显示在swiper之上 */
			display: flex;
			width: 100%;
			min-width: 1200px;
			margin: 0px 459px 0px 0px;
			/* background: pink; */
			/* align-items: center; */
		}
		.bgimage{
			width: 227px;
			height: 71px;
			
			margin-left: 100px;
			margin-right: 200px;
		}
		.bgtext{
			width: 800px;
			color: #fff;
			font-size: 16px;
			display: flex;
			align-items: baseline;
			flex: 1;
			
			margin-top: 30px;
			cursor: pointer;
			position: absolute;
			right: 0px;
			z-index: 10; /* 确保内容显示在swiper之上 */
			
			/* left: 200px; */
			/* margin-right: 453px; */
			/* margin-left: 500px; */
			
		
			
		}
	/* 	@media (max-width: 1200px) {
		    .bgtext {
		      margin-left: 100px;
		    }
		} */
		
		.bgtextlief{
			margin-left: 64px;
			font-family: PingFang SC, PingFang SC;
			font-weight: 500;
			font-size: 16px;
			color: #fff;
			line-height: 19px;
			text-align: left;
			font-style: normal;
			text-transform: none;
			position: relative;
			
		}
		.bgtextlie::after{
			content: '';
			position: absolute;
			bottom: -8px;
			left: 50%;
			transform: translateX(-50%);
			width: 32px;
			height: 2px;
			background: #FF6600;
			border-radius: 0px 0px 0px 0px;
			
		}
</style>