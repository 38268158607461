import Vue from 'vue';
/* 导入路由 */
import VueRouter from 'vue-router';
/* 导入注册我们自己的组件 */
// import home from '../components/Home.vue'

Vue.use(VueRouter)
const routes = [
	{
		path: '/',
		name: 'HomePage',
		component: () => import('../components/HomePage.vue')
	},
	{
		path: '/Home',
		name: 'Home',
		component: () => import('../components/Home.vue')
	},
	{
		path: '/About',
		name: 'About',
		component: () => import('../components/About.vue')
		
	},
	{
		path: '/Solut',
		name: 'Solut',
		component: () => import('../components/Solut.vue')
		
	},
	{
		path: '/Syhi',
		name: 'Syhi',
		component: () => import('../components/Syhi.vue')
		
	},
	{
		path: '/My',
		name: 'My',
		component: () => import('../components/My.vue')
		
	},
	{
		path: '/Top',
		name: 'Top',
		component: () => import('../components/Top.vue')
		
	},
	
	
	
	
	
]


/* 定义组件路由 */
const router = new VueRouter({
	routes
})
 const VueRouterPush = VueRouter.prototype.push
 VueRouter.prototype.push = function push (to) {
   return VueRouterPush.call(this, to).catch(err => err)
}
//导出路由对象
export default router;