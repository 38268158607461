<template>
  <div class="app">
   
	   <div >
		   <Top></Top>
		  
	   </div>
  <router-view></router-view>
  </div>
</template>

<script>

import Top from '../src/components/Top.vue'

export default {
  name: 'App',
  components: {
    Top,
	
  },
   created() {
    
}
}
</script>

<style>
/* .app{
	width: 1920px;
} */
</style>
